<template>
  <div class="user">
    <top />
    <div class="container margin">
      <side-nav>
        <div class="user__top flex flex__space-between">
          <a-breadcrumb separator=">">
            <a-breadcrumb-item>个人中心</a-breadcrumb-item>
            <a-breadcrumb-item>我的分享</a-breadcrumb-item>
            <a-breadcrumb-item>新增分享</a-breadcrumb-item>
          </a-breadcrumb>
          <div class="user__top-back" @click="back">
            <a-icon type="left" />返回
          </div>
        </div>
        <div class="user__content">
          <div class="flex user__content-item flex__align-center flex__space-between">
            <div class="user__text">文章标题：</div>
            <!-- <a-input style="width:800px" v-model="data.title" /> -->
            <a-input style="width:800px" v-model="data.title" :maxLength="30" placeholder="请输入文章标题">
              <span slot="suffix">{{ (data.title ? data.title.length : 0) + '/30' }}</span>
            </a-input>
          </div>
          <div class="flex user__content-item flex__space-between">
            <div class="user__text">文章摘要：</div>
            <a-textarea
              :auto-size="{ minRows: 3, maxRows: 5 }"
              style="width:800px"
              v-model="data.summary"
              :maxLength="200"
            >
              <span slot="suffix">{{ (data.summary ? data.summary.length : 0) + '/200' }}</span>
            </a-textarea>
          </div>
          <div class="flex user__content-item user__content-item_position">
            <div class="user__text user__text_mr uploader">封面图片：</div>
            <a-upload
              class="avatar"
              name="file"
              :multiple="false"
              list-type="picture-card"
              :show-upload-list="false"
              :headers="headers"
              :action="uploadAction"
              @change="handleChange"
            >
              <img
                v-if="coverImage"
                :src="coverImage"
                alt="avatar"
                v-bind:style="{ width: width +'px', height: height+'px' }"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
            <div class="user__text-tip">
              <div>尺寸：1920 * 1080</div>
              <div>大小：小于 2 M</div>
              <div>支持JPG / PNG / BMP 等格式的图片</div>
            </div>
          </div>
          <div class="flex user__content-item">
            <div class="user__text user__text_mr">文章內容：</div>
            <tinymce width="800" v-model="data.content" />
          </div>
          <div class="flex user__content-item flex__align-center flex__space-between">
            <div class="user__text">原文链接：</div>
            <a-input style="width:800px" v-model="data.originalLink" />
          </div>
          <div class="btn1">
            <div class="talk__back btn bott" @click="handleSubmit">确定</div>
            <div class="talk__back btn bottc" @click="back">取消</div>
          </div>
        </div>
      </side-nav>
    </div>
  </div>
</template>

<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
import { addArticle, editArticle, articleQueryById } from "@/api/community.js";
import { apiBaseUrl } from "@/config/index.js";
import { getFileAccessHttpUrl } from "@/utils/tools";
import Vue from "vue";
import { ACCESS_TOKEN } from "@/store/mutation-types.js";
import sideNav from "@/components/side-nav";
import tinymce from "@/components/tinymce";
import top from "../../components/top.vue";
export default {
  components: { sideNav, tinymce, top },
  data() {
    return {
      loading: false,
      uploadAction: apiBaseUrl + "/api/sys/common/upload",
      data: {},
      imageUrl: "",
      title: "",
      summary: "",
      coverImage: "",
      content: "",
      originalLink: "",
      width: 150,
      height: 141,
      headers: {}
    };
  },
  computed: {
    getFileAccessHttpUrl() {
      return avatar => {
        return getFileAccessHttpUrl(avatar);
      };
    }
  },
  created() {
    let token = Vue.ls.get(ACCESS_TOKEN);
    this.headers = { "X-Access-Token": token };
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData();
    }
  },
  methods: {
    getData() {
      articleQueryById({
        id: this.$route.query.id
      }).then(res => {
        this.data = res.result;
        this.coverImage = this.getFileAccessHttpUrl(this.data.coverImage);
      });
    },
    handleChange(info) {
      this.loading = true;
      if (info.file.status === "done") {
        this.$message.success(`上传成功!`);
        this.data.coverImage = info.file.response.message;
        getBase64(info.file.originFileObj, coverImage => {
          this.coverImage = coverImage;
          this.loading = false;
        });
      } else if (info.file.status === "error") {
        this.$message.error(`上传失败!`);
      }
    },
    handleSubmit() {
      const obj = {
        id: this.id,
        title: this.data.title,
        summary: this.data.summary,
        coverImage: this.data.coverImage,
        content: this.data.content,
        originalLink: this.data.originalLink,
        reviewStatus: "WAITING"
      };
      if (this.id) {
        this.edit(obj);
      } else {
        this.addSubmit(obj);
      }
      // this.$emit("change", arr);
    },
    back() {
      this.$router.back();
    },
    addSubmit(obj) {
      console.log("执行");
      const paramter = {};
      Object.assign(paramter, obj);
      addArticle(paramter)
        .then(res => {
          if (res.success) {
            this.$message.success("添加成功");
            this.back();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    edit(obj) {
      const paramter = {};
      Object.assign(paramter, obj);
      editArticle(paramter)
        .then(res => {
          if (res.success) {
            this.$message.success("修改成功");
            this.back();
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setFormValue(record) {
      console.log(record);
    }
  }
};
</script>

<style lang = "scss" scoped>
.user {
  &__top {
    padding: 0 24px 32px;
    border-bottom: 1px solid #e4e4e4;
    &-back {
      cursor: pointer;
      transition: all 0.4s;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      &:hover {
        color: #376cde;
      }
    }
  }
  &__content {
    padding-top: 45px;
    &-item {
      margin-bottom: 38px;
      &_position {
        position: relative;
      }
    }
  }
  &__text {
    width: 80px;
    &_mr {
      margin-right: 16px;
    }
    /* .avatar-uploader > .ant-upload {
      width: 150px;
      height: 128px;
    } */
    &-tip {
      position: absolute;
      bottom: 10px;
      left: 360px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }
  .btn1 {
    text-align: center;

    .btn {
      width: 63px;
      line-height: 25px;
      text-align: center;
      font-size: 14px;
      margin-right: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      border: 1px solid #999999;
      border-radius: 13px;
      display: inline-block;
      cursor: pointer;
      transition: all 0.6s;
      transform: translateX(60%);
      &:hover {
        color: #416dff;
        border: 1px solid #416dff;
      }
    }
    .bott {
      background-color: #c4a468;
    }
    .bottc {
      background-color: #b1b1b1;
    }
  }
}
</style>
