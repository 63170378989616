import axios from '@/utils/request'

// 通过的分享文章分享文章
export const articleList = (data) => {
    return axios.request({
        url: '/api/article/list',
        data,
        method: 'get'
    })
}
// 热榜分享
export const articleRankPage = (data) => {
    return axios.request({
        url: '/api/article/rank/page',
        data,
        method: 'get'
    })
}
// 通过id分享文章
export const articleQueryById = (data) => {
    return axios.request({
        url: '/api/article/queryById',
        data,
        method: 'get'
    })
}
// 点赞文章
export const articleLike = (data) => {
    return axios.request({
        url: '/api/article/like',
        data,
        method: 'post'
    })
}
// 文章编辑
export const articleEdit = (data) => {
    return axios.request({
        url: '/api/article/edit',
        data,
        method: 'put'
    })
}
// 文章评论分页列表
export const articleCommentPage = (data) => {
    return axios.request({
        url: '/api/article/comment/page',
        data,
        method: 'get'
    })
}
// 文章添加评论/api/article/comment/add
export const addArticleComment = (data) => {
    return axios.request({
        url: '/api/article/comment/add',
        data,
        method: 'post'
    })
}
// 分享文章添加/api/article/queryById
export const addArticle = (data) => {
    return axios.request({
        url: '/api/article/add',
        data,
        method: 'post'
    })
}
// 分享文章编辑
export const editArticle = (data) => {
    return axios.request({
        url: '/api/article/edit',
        data,
        method: 'put'
    })
}
// 用户浏览的文章记录
export const articleUserBrowseLog = (data) => {
    return axios.request({
        url: '/api/articleUserBrowseLog/list',
        data,
        method: 'get'
    })
}
// 用户浏览记录添加
export const addArticleUserBrowseLog = (data) => {
    return axios.request({
        url: '/api/articleUserBrowseLog/add',
        data,
        method: 'post'
    })
}
// 工具包添加
export const toolkitList = (data) => {
    return axios.request({
        url: '/api/toolkit/list',
        data,
        method: 'get'
    })
}
// 资源树articleList
export const courseCategoryList = (data) => {
    return axios.request({
        url: '/api/courseCategory/treeData',
        data,
        method: 'get'
    })
}

// 获取文件下载资源
export const getResource = (data) => {
    return axios.request({
        url: '/api/datum/download/',
        data,
        method: 'get'
    })
}